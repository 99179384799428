.section__heading {
    height:fit-content;
    font-size: 3rem;
    font-weight: 900;
    opacity: 0;
    transform: translateY(10px);
    transition: all 1s ease-out;
}
  
.section__heading-visible {
    opacity: 1;
    transform: translateY(0);
}

@media screen and (max-width: 720px) {
    .section__heading {
        font-size: 2rem;
        order: 1;
    }
}