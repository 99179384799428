.text {
    margin-top: 3rem;
    font-size: 1.1rem;
    line-height: 1.5rem;
    max-width: 40vw;
    opacity: 0;
    transition: opacity 1s ease-out;
}

.text-visible {
    opacity: 1;
}

@media screen and (max-width: 720px) {
    .text{
        font-size: 1rem;
        order: 2;
        max-width: 100vw;
        margin-top: 1rem;
        margin-bottom: 1rem;
    }

}